import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdCheckCircle } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Гольф-клуб Green Horizons
			</title>
			<meta name={"description"} content={"Розташований серед пишних ландшафтів і незайманих фарватерів, гольф-клуб Green Horizons - це найкраще місце для неперевершеної гри в гольф."} />
			<meta property={"og:title"} content={"Гольф-клуб Green Horizons"} />
			<meta property={"og:description"} content={"Розташований серед пишних ландшафтів і незайманих фарватерів, гольф-клуб Green Horizons - це найкраще місце для неперевершеної гри в гольф."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6698fbc89335410018c4cb34/images/golf-icon-1975x2048-c3av3pb5.png?v=2024-07-18T13:01:11.163Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6698fbc89335410018c4cb34/images/golf-icon-1975x2048-c3av3pb5.png?v=2024-07-18T13:01:11.163Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6698fbc89335410018c4cb34/images/golf-icon-1975x2048-c3av3pb5.png?v=2024-07-18T13:01:11.163Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6698fbc89335410018c4cb34/images/golf-icon-1975x2048-c3av3pb5.png?v=2024-07-18T13:01:11.163Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6698fbc89335410018c4cb34/images/golf-icon-1975x2048-c3av3pb5.png?v=2024-07-18T13:01:11.163Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6698fbc89335410018c4cb34/images/golf-icon-1975x2048-c3av3pb5.png?v=2024-07-18T13:01:11.163Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6698fbc89335410018c4cb34/images/golf-icon-1975x2048-c3av3pb5.png?v=2024-07-18T13:01:11.163Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Header>
		<Section md-padding="70px 0 80px 0" quarkly-title="Product-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6698fbc89335410018c4cb34/images/golf-icon-1975x2048-c3av3pb5.png?v=2024-07-18T13:01:11.163Z) 0 0/contain no-repeat scroll padding-box"
					padding="100px 50px 100px 50px"
					md-margin="0px 0px 50px 0px"
					md-padding="50px 50px 50px 50px"
				>
					<Image
						src="https://uploads.quarkly.io/6698fbc89335410018c4cb34/images/1-3.jpg?v=2024-07-18T12:57:21.738Z"
						display="block"
						max-width="100%"
						width="350px"
						srcSet="https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/1-3.jpg?v=2024-07-18T12%3A57%3A21.738Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/1-3.jpg?v=2024-07-18T12%3A57%3A21.738Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/1-3.jpg?v=2024-07-18T12%3A57%3A21.738Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/1-3.jpg?v=2024-07-18T12%3A57%3A21.738Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/1-3.jpg?v=2024-07-18T12%3A57%3A21.738Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/1-3.jpg?v=2024-07-18T12%3A57%3A21.738Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/1-3.jpg?v=2024-07-18T12%3A57%3A21.738Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					padding="0px 40px 0px 40px"
					lg-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 40px 0px" font="normal 500 42px/1.2 --fontFamily-serifGaramond" sm-margin="0px 0px 30px 0px">
						Комплексні гольф-послуги для кожного ентузіаста
					</Text>
					<Text margin="0px 0px 50px 0px" font="normal 300 20px/1.5 --fontFamily-sans" color="#656b70">
						У гольф-клубі Green Horizons ми виходимо за рамки гри, щоб запропонувати вам винятковий досвід гри в гольф. Від індивідуальних тренувань до першокласного обладнання - наш асортимент послуг розроблений таким чином, щоб задовольнити будь-які потреби, гарантуючи, що ваш час з нами буде неперевершеним. Дізнайтеся, як ми можемо допомогти вам вдосконалити свою гру та отримати більше задоволення від гри на полі.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						lg-align-self="center"
						md-align-self="auto"
						sm-flex-direction="column"
						sm-align-items="center"
						sm-align-self="center"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-10"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				width="100%"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="48px"
				flex-direction="column"
				flex-wrap="no-wrap"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline2" color="--light" margin="0px 0px 6px 0px">
							Персоналізовані тренування та коучинг
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							У гольф-клубі Green Horizons ми розуміємо, що кожен гравець у гольф унікальний. Саме тому ми пропонуємо персоналізовані тренування та коучинг, адаптовані до ваших конкретних потреб. Наша команда професійних інструкторів допоможе вам вдосконалити свої навички, незалежно від того, чи ви новачок, чи досвідчений гравець, який прагне вдосконалити свою техніку. Наші комплексні навчальні програми охоплюють все - від базової механіки замаху до просунутих стратегій, гарантуючи, що ви досягнете своїх цілей у гольфі.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline2" color="--light" margin="0px 0px 6px 0px">
							Найсучасніші тренувальні майданчики
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Наш клуб має найсучасніше тренувальне обладнання, призначене для того, щоб допомогти вам відточити свої навички. Наше поле для гольфу обладнане за останнім словом техніки, щоб відстежувати ваші удари і надавати миттєвий зворотній зв'язок, в той час як наші паттінг-гріни і майданчики для короткої гри пропонують різноманітні завдання, щоб відточити вашу точність і відчуття удару. Маючи у своєму розпорядженні такі умови, ви зможете відпрацьовувати кожен аспект своєї гри та постійно вдосконалюватися.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-red"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline2" color="--light" margin="0px 0px 6px 0px">
							Виняткове обслуговування курсів
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Підтримка найвищих стандартів якості поля є головним пріоритетом Green Horizons. Наша спеціалізована команда з догляду за полем невтомно працює над тим, щоб наше поле завжди було в ідеальному стані. Від ідеально доглянутих фервеїв до доглянутих зелених насаджень - кожній деталі приділяється особлива увага, щоб забезпечити вам оптимальний ігровий досвід кожного разу, коли ви відвідуєте гольф-клуб.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Product-1">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 60px 0px"
				font="normal 600 56px/1.2 --fontFamily-serifGaramond"
				width="50%"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				md-margin="0px 0px 40px 0px"
			>
				Що пропонує Green Horizons Experience
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/6698fbc89335410018c4cb34/images/2-5.jpg?v=2024-07-18T12:57:23.587Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						srcSet="https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/2-5.jpg?v=2024-07-18T12%3A57%3A23.587Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/2-5.jpg?v=2024-07-18T12%3A57%3A23.587Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/2-5.jpg?v=2024-07-18T12%3A57%3A23.587Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/2-5.jpg?v=2024-07-18T12%3A57%3A23.587Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/2-5.jpg?v=2024-07-18T12%3A57%3A23.587Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/2-5.jpg?v=2024-07-18T12%3A57%3A23.587Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/2-5.jpg?v=2024-07-18T12%3A57%3A23.587Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 28px/1.2 --fontFamily-serifGaramond" text-align="center">
						Індивідуальні тренувальні програми
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						text-align="center"
						font="--base"
						padding="0px 35px 0px 35px"
						lg-padding="0px 0 0px 0"
					>
						Індивідуальні заняття: Індивідуальні заняття з нашими професійними тренерами.{"\n"}
						<br />
						{"    "}Групові заняття: Приєднуйтесь до інших у спільному навчальному середовищі.{"\n"}
						<br />
						{"    "}Програми для юніорів: спеціалізовані тренування для юних гравців у гольф.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/6698fbc89335410018c4cb34/images/1-1.jpg?v=2024-07-18T12:57:23.575Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						srcSet="https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/1-1.jpg?v=2024-07-18T12%3A57%3A23.575Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/1-1.jpg?v=2024-07-18T12%3A57%3A23.575Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/1-1.jpg?v=2024-07-18T12%3A57%3A23.575Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/1-1.jpg?v=2024-07-18T12%3A57%3A23.575Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/1-1.jpg?v=2024-07-18T12%3A57%3A23.575Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/1-1.jpg?v=2024-07-18T12%3A57%3A23.575Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6698fbc89335410018c4cb34/images/1-1.jpg?v=2024-07-18T12%3A57%3A23.575Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 28px/1.2 --fontFamily-serifGaramond" text-align="center">
						Удосконалені тренувальні майданчики
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						text-align="center"
						font="--base"
						padding="0px 35px 0px 35px"
						lg-padding="0px 0 0px 0"
					>
						Автодром: Відстежуйте свої удари та отримуйте миттєвий зворотній зв'язок.
    Тренуйте свою точність і силу удару.{"\n"}
						<br />
						{"    "}Зона для короткої гри: Відточуйте свої навички відколювання та подачі м'яча.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://images.unsplash.com/photo-1622115837997-90c89ae689f9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						srcSet="https://images.unsplash.com/photo-1622115837997-90c89ae689f9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1622115837997-90c89ae689f9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1622115837997-90c89ae689f9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1622115837997-90c89ae689f9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1622115837997-90c89ae689f9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1622115837997-90c89ae689f9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1622115837997-90c89ae689f9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 28px/1.2 --fontFamily-serifGaramond" text-align="center">
						Вишукані страви для гурманів
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						text-align="center"
						font="--base"
						padding="0px 35px 0px 35px"
						lg-padding="0px 0 0px 0"
					>
						Ресторан: Скуштуйте вишукані страви, приготовані нашими талановитими кухарями.{"\n"}
						<br />
						{"    "}Бар і лаунж: Розслабтеся з напоєм у затишній та елегантній обстановці.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-10"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					Зв'яжіться з нами для отримання додаткової інформації
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="90%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					Щоб дізнатися більше про повний спектр послуг, які ми пропонуємо, запрошуємо вас зв'язатися з нами або відвідати гольф-клуб Green Horizons. Наш привітний персонал завжди готовий допомогти вам і надати детальну інформацію з урахуванням ваших інтересів. І це лише короткий огляд того, що ми можемо запропонувати. Відкрийте для себе повний спектр наших пропозицій, зв'язавшись з нами сьогодні.
				</Text>
			</Box>
		</Section>
		<Components.Header />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac2cae8900d0022f35234"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});